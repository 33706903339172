import React, { useState } from "react";

import { apiFetch } from "../../../apiBindings";
import pushToAnalytics from "../../../utils/analytics";
import StorageHandler from "../../../utils/StorageHandler";
import Validator from "../../../utils/validation/Validator";
import Columns from "../Columns";
import FormError from "../forms/FormError";
import NewCheckbox from "../forms/NewCheckbox";
import NewInput from "../forms/NewInput";
import LoadingIndicator from "../LoadingIndicator";
import Button from "../NewForms/Button";
import NewHeadline from "../NewHeadline";
import { UncontrolledNotification as Notification } from "../Notification";

const ContactForm = ({
    canSendStorageString,
    urlEnding,
    pushToGTMString,
    scrollToAnchor,
    useFormalAddress,
    lang = "de",
}) => {
    const storage = new StorageHandler();

    /**
     * If the user didn't sent something or the last sending ist older or equals 5 minutes ago,
     * he can send again.
     *
     * @param {*} timestamp
     */
    const canSend = (timestamp) => {
        if (timestamp === null) {
            return true;
        }
        return Math.floor((Date.now() - parseInt(timestamp)) / 1000 / 60) >= 5;
    };

    const [sending, setSending] = useState(false);
    const [sendingError, setSendingError] = useState(false);
    const [errors, setErrors] = useState([]);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [isPrivacyPolicyAccepted, setIsPrivacyPolicyConfirmed] =
        useState(false);
    const [emailSent, setEmailSent] = useState(
        !canSend(storage.get(canSendStorageString, null))
    );
    const validator = new Validator();

    const isValidName = validator.getValidator("name", name, true);
    const isValidPhone = validator.getValidator("phone", phone, true);
    const isValidEmail = validator.getValidator("email", email, true);

    const onSubmit = (event) => {
        event.preventDefault();

        if (errors.length === 0) {
            // Check if the fields are valid
            if (
                name === "" ||
                !isValidName() ||
                phone === "" ||
                !isValidPhone() ||
                email === "" ||
                !isValidEmail() ||
                message === ""
            ) {
                const localErrors = [...errors];

                if (name === "") {
                    localErrors.push("name");
                } else if (!isValidName()) {
                    localErrors.push("name");
                }
                if (phone === "") {
                    localErrors.push("phone");
                } else if (!isValidPhone()) {
                    localErrors.push("phone");
                }
                if (email === "") {
                    localErrors.push("email");
                } else if (!isValidEmail()) {
                    localErrors.push("email");
                }
                if (message === "") {
                    localErrors.push("message");
                }
                if (isPrivacyPolicyAccepted === false) {
                    localErrors.push("isPrivacyPolicyAccepted");
                }
                setErrors(localErrors);
                return;
            }
            setSending(true);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            apiFetch("/contact/" + String(urlEnding), {
                method: "post",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: JSON.stringify({
                    name,
                    phone,
                    email,
                    message,
                    lang,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        storage.set(canSendStorageString, Date.now());
                        storage.set("app:uid", data.appUid);
                        setEmailSent(true);
                        setSendingError(false);
                        pushToAnalytics("contact-form:sent", {
                            form: pushToGTMString,
                        });
                    } else {
                        setSendingError(true);
                    }
                    setSending(false);
                });
        }
    };

    const onChangeName = (event) => {
        setName(event.target.value);
        if (event.target.value !== "" && isValidName()) {
            clearError("name");
        }
    };

    const onChangePhone = (event) => {
        setPhone(event.target.value);
        if (event.target.value !== "" && isValidPhone()) {
            clearError("phone");
        }
    };

    const onChangeEmail = (event) => {
        setEmail(event.target.value);
        if (event.target.value !== "" && isValidEmail) {
            clearError("email");
        }
    };

    const onChangeMessage = (event) => {
        setMessage(event.target.value);
        if (event.target.value !== "") {
            clearError("message");
        }
    };

    const onIsPrivacyPolicyConfirmed = ({ target }) => {
        setIsPrivacyPolicyConfirmed(target.value);
        if (target.value) {
            clearError("isPrivacyPolicyAccepted");
        } else {
            addFormError("isPrivacyPolicyAccepted");
        }
    };

    const addFormError = (key) => {
        if (!errors.includes(key)) {
            setErrors([...errors, key]);
        }
    };

    const hasErrorAt = (key) => {
        return errors.filter((item) => item === key).length > 0;
    };

    /**
     * Remove an element from the errors.
     *
     * @param {*} key
     */
    const clearError = (key) => {
        setErrors(errors.filter((item) => item !== key));
    };

    const translations = {
        de: {
            mesage: useFormalAddress
                ? "Ihre Nachricht an uns:"
                : "Deine Nachricht an uns:",
            name: "Name",
            email: "E-Mail",
            phone: "Telefon",
            messageShort: useFormalAddress
                ? "Ihre Nachricht"
                : "Deine Nachricht",
            checkBox: "Ich akzeptiere die ",
            privacyPolicy: "Datenschutzerklärung",
            privacyPolicyLink: "/datenschutz",
            submit: "Nachricht senden",
            loading: useFormalAddress
                ? "Ihre Nachricht wird übermittelt. Bitte haben Sie einen Moment Geduld."
                : "Deine Nachricht wird übermittelt. Bitte hab einen Moment Geduld.",
            thankYou: useFormalAddress
                ? "Vielen Dank für Ihre Anfrage"
                : "Vielen Dank für Deine Anfrage",
            receive: useFormalAddress
                ? "Wir haben Ihre Anfrage erhalten und melden uns in Kürze bei Ihnen."
                : "Wir haben Deine Anfrage erhalten und melden uns in Kürze bei Dir.",
            error: useFormalAddress
                ? "Es ist leider ein Fehler aufgetreten. Versuchen Sie es bitte in Kürze erneut."
                : "Es ist leider ein Fehler aufgetreten. Versuche es bitte in Kürze erneut.",
            sorry: "Entschuldigung",
            formular: "Zum Formular",
            messageError: useFormalAddress
                ? "Geben Sie bitte eine Nachricht ein."
                : "Gib bitte eine Nachricht ein.",
            messageErrorIsPrivacyPolicyAccepted: useFormalAddress
                ? "Sie müssen die Datenschutzerklärung akzeptieren, um fortfahren zu können."
                : "Du musst die Datenschutzerklärung akzeptieren, um fortfahren zu können.",
            messageErrorPhone: useFormalAddress
                ? "Bitte geben Sie eine Telefonnummer ein."
                : "Gib bitte eine Telefonnummer ein.",
            messageErrorEmail: useFormalAddress
                ? "Bitte geben Sie eine E-Mail ein."
                : "Gib bitte eine E-Mail ein.",
            messageErrorName: useFormalAddress
                ? "Bitte geben Sie ihren Namen ein."
                : "Gib bitte deinen Namen ein.",
        },
        en: {
            mesage: "Your message to us:",
            name: "Name",
            email: "E-Mail",
            phone: "Telephone",
            messageShort: "Your message",
            checkBox: "I accept the ",
            privacyPolicy: "privacy policy",
            privacyPolicyLink: "/privacy-policy",
            submit: "Submit",
            loading: "Your message is being sent. Please wait.",
            thankYou: "Thank you for your inquiry",
            receive:
                "We have received your inquiry and will get in touch with you as soon as possible.",
            error: "There was an error. Please try again.",
            sorry: "We are sorry",
            formular: "Form",
            messageError: "Please enter a message.",
            messageErrorIsPrivacyPolicyAccepted:
                "You must accept the privacy policy to proceed.",
            messageErrorPhone: "Please enter a phone number.",
            messageErrorEmail: "Please enter an E-Mail.",
            messageErrorName: "Please enter your name.",
        },
        pl: {
            mesage: "Twoja wiadomość:",
            name: "Imię i nazwisko",
            email: "E-Mail",
            phone: "Telefon",
            messageShort: "Twoja wiadomość",
            checkBox: "Akceptuję ",
            privacyPolicy: "politykę prywatności",
            privacyPolicyLink: "/polityka-prywatnosci",
            submit: "Prześlij wiadomość",
            loading:
                "Twoja wiadomość została przesłana. Prosimy o cierpliwość.",
            thankYou: "Dziękujemy za zapytanie.",
            receive: "Otrzymaliśmy Twoje zapytanie i wkrótce na nie odpowiemy.",
            error: "Niestety wystąpił błąd. Spróbuj ponownie za chwilę.",
            sorry: "Przepraszam",
            formular: "Do formy",
            messageError: "Wpisz treść wiadomości.",
            messageErrorIsPrivacyPolicyAccepted:
                "Aby kontynuować, musisz zaakceptować politykę prywatności.",
            messageErrorPhone: "Proszę podać numer telefonu.",
            messageErrorEmail: "Proszę podać adres e-mail.",
            messageErrorName: "Proszę podać swoje imię.",
        },
    };

    return (
        <div
            id={scrollToAnchor}
            style={{
                marginTop: "-70px",
                paddingTop: "70px",
            }}
        >
            {sendingError ? (
                <Notification>
                    <NewHeadline>{translations[lang].sorry}</NewHeadline>
                    <p>{translations[lang].error}</p>
                    <Button onClick={() => setSendingError(false)}>
                        {translations[lang].formular}
                    </Button>
                </Notification>
            ) : sending ? (
                <Notification>
                    <LoadingIndicator>
                        {translations[lang].loading}
                    </LoadingIndicator>
                </Notification>
            ) : emailSent ? (
                <Notification>
                    <NewHeadline>{translations[lang].thankYou}</NewHeadline>
                    <p>{translations[lang].receive}</p>
                </Notification>
            ) : (
                <>
                    <NewHeadline>{translations[lang].mesage}</NewHeadline>
                    <form onSubmit={onSubmit}>
                        <Columns>
                            <Columns.Column>
                                <NewInput
                                    value={name}
                                    onChange={onChangeName}
                                    placeholder={translations[lang].name}
                                />
                                {hasErrorAt("name") && (
                                    <FormError
                                        message={
                                            translations[lang].messageErrorName
                                        }
                                    />
                                )}
                                <NewInput
                                    value={email}
                                    onChange={onChangeEmail}
                                    placeholder={translations[lang].email}
                                />
                                {hasErrorAt("email") && (
                                    <FormError
                                        message={
                                            translations[lang].messageErrorEmail
                                        }
                                    />
                                )}
                                <NewInput
                                    value={phone}
                                    onChange={onChangePhone}
                                    placeholder={translations[lang].phone}
                                />
                                {hasErrorAt("phone") && (
                                    <FormError
                                        message={
                                            translations[lang].messageErrorPhone
                                        }
                                    />
                                )}
                            </Columns.Column>
                            <Columns.Column>
                                <NewInput
                                    type="textarea"
                                    placeholder={
                                        translations[lang].messageShort
                                    }
                                    onChange={onChangeMessage}
                                    value={message}
                                    fullHeight
                                />
                                {hasErrorAt("message") && (
                                    <FormError
                                        message={
                                            translations[lang].messageError
                                        }
                                    />
                                )}
                            </Columns.Column>
                        </Columns>

                        <Columns>
                            <Columns.Column>
                                <NewCheckbox
                                    checked={isPrivacyPolicyAccepted}
                                    name="isPrivacyPolicyAccepted"
                                    required
                                    onChange={onIsPrivacyPolicyConfirmed}
                                >
                                    {translations[lang].checkBox}
                                    <a
                                        href={
                                            translations[lang].privacyPolicyLink
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {" "}
                                        {translations[lang].privacyPolicy}
                                    </a>
                                </NewCheckbox>
                                {hasErrorAt("isPrivacyPolicyAccepted") && (
                                    <FormError
                                        message={
                                            translations[lang]
                                                .messageErrorIsPrivacyPolicyAccepted
                                        }
                                    />
                                )}
                            </Columns.Column>
                            <Columns.Column>
                                <Button fullWidth data-gtag={pushToGTMString}>
                                    {translations[lang].submit}
                                </Button>
                            </Columns.Column>
                        </Columns>
                    </form>
                </>
            )}
        </div>
    );
};

export default ContactForm;
