import React from "react";

import ContactForm from "../../../../ContactForm";

const AdBlueContact = ({ name = "adblue" }) => {
    return (
        <ContactForm
            phoneHeadline="0 42 62 – 79 99 61"
            phoneLink="+494262799961"
            emailHeadline="ab.Online@hoyer.de"
            urlEnding="adblue"
            pushToGTMString={`${name}:contact-form`}
            canSendStorageString={`${name}::contact-form:sent-at`}
            scrollToAnchor="contact"
        />
    );
};

export default AdBlueContact;
